import React from 'react';
import { Col, Image} from 'react-bootstrap';

import '../assets/css/mystyle.css';

import 'bootstrap/dist/css/bootstrap.min.css';

class Card extends React.Component {

    super(props) {

    }

	render() {
        return (
            <Col xs={12} md={4} className="card-section-container">
                <div className={this.props.backColor + " card-section"} >
                    <div className="card-title">
                        <Image src={ this.props.img_src }  alt="video" className="w-15" />
                        <span className="cl-white">{ this.props.title }</span>
                    </div>
                    <div className="card-description">
                        <p className="card-text-size cl-dark-grey">
                            {this.props.content}
                        </p>
                    </div>
                </div>
            </Col>
        );
    }
}

export default Card;
