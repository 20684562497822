import React from 'react';
import "../App.css";
// eslint-disable-next-line
import { Col, Row} from "react-bootstrap";
import "../assets/css/mystyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import Googlesheet from './components/Googlesheet';
const Prerequisite = () =>{
    return (
        <div className="App ">
      <div className="bg-body p-10">
        <div className="content-container">
          <div className="container-fluid">
            <div className="bg-dark-black bdRadius-top pt-5">
                <center><h1 className="mark-aws cl-gold-yellow">Pre-requisites!</h1></center>
              <Row className="section-container pad-5 justify-center">
                <Col xs={12} md={12} lg={8} xl={6}>
                  <div className="sec-img">
                    <div className="bg-black-body p-3">
                      <div className="terminal-icons">
                        <span>
                          <img
                            src="../assets/img/terminal-red.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-yellow.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-green.png"
                            alt="terminal-icon"
                          />
                        </span>
                      </div>
                    </div>
                    <iframe title="nodejs" width="560" height="315" src="https://www.youtube.com/embed/pKd0Rpw7O48" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="margin-responsive mt-4 pt-2"
                >
                  <div className="video-description mb-5">
                    <p className="video-description-content cl-dark-grey">
                      First pre-requisites is Node.js! 
                      <br />
                      In this you will learn a crash course on Node.JS to give you a good start!
                      <br />
                      All credit for this goes to Programming with Mosh
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="section-container pad-5 justify-center">
                <Col xs={12} md={12} lg={8} xl={6}>
                  <div className="sec-img">
                    <div className="bg-black-body p-3">
                      <div className="terminal-icons">
                        <span>
                          <img
                            src="../assets/img/terminal-red.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-yellow.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-green.png"
                            alt="terminal-icon"
                          />
                        </span>
                      </div>
                    </div>
                    <iframe title="asyncawait" width="560" height="315" src="https://www.youtube.com/embed/PoRJizFvM7s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="margin-responsive mt-4 pt-2"
                >
                  <div className="video-description mb-5">
                    <p className="video-description-content cl-dark-grey">
                      Second pre-requisites is learning about async/await , promises and callback! 
                      <br />
                      This gives you an insight on how API calls will be done and why we use things such as async/await
                      <br />
                      All credit for this goes to Travis Media
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="section-container pad-5 justify-center">
                <Col xs={12} md={12} lg={8} xl={6}>
                  <div className="sec-img">
                    <div className="bg-black-body p-3">
                      <div className="terminal-icons">
                        <span>
                          <img
                            src="../assets/img/terminal-red.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-yellow.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-green.png"
                            alt="terminal-icon"
                          />
                        </span>
                      </div>
                    </div>
                    <iframe title="git" width="560" height="315" src="https://www.youtube.com/embed/SWYqp7iY_Tc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="margin-responsive mt-4 pt-2"
                >
                  <div className="video-description mb-5">
                    <p className="video-description-content cl-dark-grey">
                      Third pre-requisites is learning about Git and Github.
                      <br />
                      In here you will learn about how to use Git on your computer so you can push your code to Github
                      <br />
                      All credit for this goes to Travis Media
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="section-container pad-5 justify-center">
                <Col xs={12} md={12} lg={8} xl={6}>
                  <div className="sec-img">
                    <div className="bg-black-body p-3">
                      <div className="terminal-icons">
                        <span>
                          <img
                            src="../assets/img/terminal-red.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-yellow.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-green.png"
                            alt="terminal-icon"
                          />
                        </span>
                      </div>
                    </div>
                    <iframe title="cloudcomputing" width="560" height="315" src="https://www.youtube.com/embed/dH0yz-Osy54" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="margin-responsive mt-4 pt-2"
                >
                  <div className="video-description mb-5">
                    <p className="video-description-content cl-dark-grey">
                      Fourth pre-requisites is learning about what cloud computing is.
                      <br />
                      In here you will learn from AWS what cloud computing is and the same applies to GCP, IBM Cloud and Azure.
                      <br />
                      All credit for this goes to AWS
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="section-container pad-5 justify-center">
                <Col xs={12} md={12} lg={8} xl={6}>
                  <div className="sec-img">
                    <div className="bg-black-body p-3">
                      <div className="terminal-icons">
                        <span>
                          <img
                            src="../assets/img/terminal-red.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-yellow.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="../assets/img/terminal-green.png"
                            alt="terminal-icon"
                          />
                        </span>
                      </div>
                    </div>
                    <iframe title="reactjs" width="560" height="315" src="https://www.youtube.com/embed/sBws8MSXN7A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="margin-responsive mt-4 pt-2"
                >
                  <div className="video-description mb-5">
                    <p className="video-description-content cl-dark-grey">
                      Fifth pre-requisites is learning about what React JS. This pre-requisite is not needed at all, but if your curious then definetly!
                      <br />
                      React.js is a javascript library for building user interface, and this library has been craeted by Facebook and is maintained by them.
                      <br />
                      All credit for this goes to Travis media
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
export default Prerequisite;