import React from 'react';
import { Col, Image} from 'react-bootstrap';

import '../assets/css/mystyle.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import picture from '../assets/img/multicloud.png'; 

class Stack extends React.Component {

    super(props) {

    }

	render() {
        return (
            <Col xs={12} md={8} lg={6} className="section-left" >
                <div className="pad-3">
                    <div className="bg-dark-black">
                        <div className="bg-black-body p-3">
                            <div className="terminal-icons">
                                <span><img src="./assets/img/terminal-red.png" alt="terminal-icon" /></span>
                                <span><img src="./assets/img/terminal-yellow.png" alt="terminal-icon" /></span>
                                <span><img src="./assets/img/terminal-green.png" alt="terminal-icon" /></span>
                            </div>
                        </div>
                        <Image src={picture}  alt="multicloud" className="w-100" />
                        <div className="subsec-padding">
                            <div className="subsection-title">
                                <p>{this.props.title}</p>
                            </div>	
                            <div className="subsection-description">
                                <p>{this.props.content}</p>
                            </div>
                        </div>	
                        
                    </div>
                </div>
            </Col>
        );
    }
}

export default Stack;
