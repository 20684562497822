import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Prerequisite from './components/Prerequisite';

class App extends Component {
    render() {
      return(
        <BrowserRouter>
          < div className = "App" > 
            <Switch>
              <Route exact path='/' component={LandingPage} />
              <Route path='/multicloud/prerequisite' component={Prerequisite} />
            </Switch>
          </div>
        </BrowserRouter>
      );
    }
}

export default App;
