import React from "react";
import "../App.css";
// eslint-disable-next-line
import { Col, Image, Row, Button } from "react-bootstrap";
import TextLoop from "react-text-loop";
import "../assets/css/mystyle.css";
import Card from "./Card";
import Stack from "./Stack";
import "bootstrap/dist/css/bootstrap.min.css";
//import Googlesheet from './components/Googlesheet';

const LandingPage = () => {
  let state = {
    card_data: [
      {
        img_src: "./assets/img/icon-chat.png",
        title: "Slack Chat",
        content: "A community with like minded people",
      },
      {
        img_src: "./assets/img/icon-open-source.png",
        title: "A-Z",
        content: "We do everything from scratch",
      },
      {
        img_src: "./assets/img/icon-cake.png",
        title: "Cake",
        content: "There is always a surprise!",
      },
    ],
    stack_data: [
      //{img_src: "./assets/img/banner-started-left.png", title: "Flutter App Course", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
      {
        img_src: "./assets/img/multicloud.png",
        title: "Multi-cloud Development",
        content:
          "Learn to build serverless applications on AWS, Azure, IBM cloud & GCP",
      },
    ],
    google_sheet_data: [
      {
        title: "Google Secret Manager with Firebase",
        subtitle:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.",
      },
      {
        title: "Google Secret Manager with Firebase",
        subtitle:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.",
      },
      {
        title: "Google Secret Manager with Firebase",
        subtitle:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.",
      },
      {
        title: "Google Secret Manager with Firebase",
        subtitle:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.",
      },
      {
        title: "Google Secret Manager with Firebase",
        subtitle:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.",
      },
      {
        title: "Google Secret Manager with Firebase",
        subtitle:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.",
      },
    ],
  };

  function indiegogo() {
    window.open(
      "https://www.udemy.com/course/multi-cloud-deployment-with-serverless-framework",
      "_blank"
    );
  }

  return (
    <div className="App ">
      <div className="bg-body p-10">
        <div className="content-container">
          <div className="container-fluid">
            <div className="bg-dark-black bdRadius-top pt-5">
              <Row className="section-container pad-5 justify-center">
                <Col xs={12} md={12} lg={8} xl={6}>
                  <div className="sec-img">
                    <div className="bg-black-body p-3">
                      <div className="terminal-icons">
                        <span>
                          <img
                            src="./assets/img/terminal-red.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="./assets/img/terminal-yellow.png"
                            alt="terminal-icon"
                          />
                        </span>
                        <span>
                          <img
                            src="./assets/img/terminal-green.png"
                            alt="terminal-icon"
                          />
                        </span>
                      </div>
                    </div>
                    <iframe
                      width="560"
                      height="315"
                      title="campaign video"
                      src="https://www.youtube.com/embed/EiVaLQIgVN4?controls=0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="margin-responsive mt-4 pt-2"
                >
                  <div className="video-title mb-4">
                    <p className="video-title-content cl-white">
                      Build and deploy to
                      <TextLoop>
                        <span className="mark-aws cl-gold-yellow"> AWS</span>
                        <span className="mark-aws cl-gold-yellow"> IBM</span>
                        <span className="mark-aws cl-gold-yellow"> Azure</span>
                        <span className="mark-aws cl-gold-yellow"> GCP</span>
                      </TextLoop>{" "}
                    </p>
                  </div>
                  <div className="video-description mb-5">
                    <p className="video-description-content cl-dark-grey">
                      Learn to build and deploy applications on AWS, Azure, GCP,
                      IBM cloud and many more!
                      <br />
                      Get started with our new course on Multi-cloud development
                      with serverless framework!
                    </p>
                  </div>
                  <div className="video-button">
                    <Button
                      onClick={indiegogo}
                      variant="success"
                      className="video-button-content"
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="section-container pad-5 mt-5">
                {state.card_data.map((elem, i) => (
                  <Card {...elem} key={i} backColor="bg-light-black" />
                ))}
              </Row>
            </div>

            <div className="bg-light-black sec-stack">
              <div className="section-title">Learn By Doing</div>
              <div className="section-subtitle mb-5">
                TAKE OUR FIRST COURSE ON MULTI-CLOUD DEVELOPMENT WITH SERVERLESS
                FRAMEWORK
              </div>
              <div className="section-container">
                <Row className="justify-center">
                  {state.stack_data.map((elem, i) => (
                    <Stack {...elem} key={i} />
                  ))}
                </Row>
              </div>
              <div className="stack-button m-5">
                <Button
                  onClick={indiegogo}
                  variant="success"
                  className="video-button-content"
                  size="lg"
                >
                  Get Started
                </Button>
              </div>
            </div>

            {/* <div className="bg-dark-black sec-stack">
								<Row>
									<Col xs={12} sm={6} className="trust-sheet mt-4 pt-2">
										<div className="section-title">
											Trust by Developers
										</div>
										<div className="section-subtitle mb-2">
											"I HAVE A JOB THANKS TO YOU"
										</div>
										<div className="sec-img">
											<Image src="./assets/img/banner-trust-dev.png" className="w-100" />
										</div>
									</Col>
									<Col xs={12} sm={6} className="trust-sheet">
										<div className="sec-img">
											<div className="bg-black-body p-3">
												<div className="terminal-icons">
													<span><img src="./assets/img/terminal-red.png" alt="terminal-icon" /></span>
													<span><img src="./assets/img/terminal-yellow.png" alt="terminal-icon" /></span>
													<span><img src="./assets/img/terminal-green.png" alt="terminal-icon" /></span>
												</div>
											</div>
											<iframe width="560" height="260" src="https://www.youtube.com/embed/tnGaCZZ5Z28" allow="autoplay; fullscreen; encrypted-media; picture-in-picture" allowfullscreen frameborder="0"></iframe>
										</div>
									</Col>
								</Row>
							</div> */}

            {/* <div className="bg-light-black sec-stack">
								<div className="section-title">
									Not your Average Code Tutorials
								</div>
								<div className="section-subtitle mb-5">
									ALL LESSONS ARE BACKED BY EXPERT SUPPORT
								</div>
								<div className="section-container">
									<Row className="justify-center">

										{
											this.state.card_data.map( (elem, i) => (<Card {...elem} key={i} backColor="bg-dark-black" />) )
										}

									</Row>
								</div>
							</div> */}

            {/* <div className="bg-dark-black sec-stack">
								<div className="section-title">
									Watch the Latest Lessons
								</div>
								<div className="section-subtitle mb-5">
									NEW VIDEO CONTENT EVERY FEW DAYS
								</div>
								<div className="section-container">
									<Row className="justify-center">

										{
											this.state.google_sheet_data.map((elem, i) => (<Googlesheet {...elem} key={i} />) )
										}

									</Row>
								</div>
								<div className="stack-button mt-5">
									<Button variant="success" className="video-button-content" size="lg">
										Get Started
									</Button>
								</div>
							</div> */}

            <div className="bg-light-black sec-stack bdRadius-bottom footer">
              <div className="footer-menu mb-5 pb-3">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={4}>
                    <div className="section-title">About Us</div>
                    <div className="section-subtitle mt-2">
                      Cloudship is a learning platform where you gain all
                      necessary skills to be cloud guru
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <div className="section-title">Quick Links</div>
                    <div className="mt-2">
                      <div className="section-subtitle">Features</div>
                      <div className="section-subtitle">Blog</div>
                      <div className="section-subtitle">Contact Us</div>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <div className="section-title">Contact</div>
                    <div className="section-subtitle mt-2">
                      themulticloudproject@gmail.com
                    </div>
                  </Col>
                  {/* <Col xs={12} sm={12} md={4} lg={4} >
											<div className="section-title">
												Newsletter
											</div>
											<div className="section-subtitle mt-2 footer-input-box">
												<div className="d-flex">
													<input type="text" className="footer-input" placeholder="Enter Your Email" />
													<button className="btn-submit"><Image src={ require("./assets/img/right-arrow.png") } alt="submit" /></button>
												</div>
											</div>
										</Col> */}
                </Row>
              </div>
              <div className="footer-bottom mt-5">
                <Row className=" pb-3">
                  <Col className="justify-between">
                    <div className="social-icons">
                      <span>
                        <i className="fa">&#xf09a;</i>
                      </span>
                      <span>
                        <i className="fa">&#xf099;</i>
                      </span>
                      <span>
                        <i className="fa">&#xf16d;</i>
                      </span>
                    </div>
                    <div className="copyright">
                      <span>&copy; 2020 All Rights Reserved</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
